import React from 'react';
import './about.css';

const AboutPage = () => {
    
    return (
       <>
        <div className="terms-container" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.jpg)` }}>
            <div className='our_terms'>
                <p className='head'>About Us</p>
                <div className="row w-100 d-none d-lg-flex">
                    <div className="col text-center">
                        <img className="fitImage" src="/images/logo-large.png" />
                    </div>
                    <div className="col">
                        <div className="storyTitle text-light">Our Story</div>
                        <div className="text-light storyText">
                            Welcome to the Middle Eastern and North African Chamber of Commerce (MENACC), a dynamic and forward-thinking organization dedicated to fostering business growth and economic collaboration of the Middle East and North Africa owned businesses in the United States of America. Founded on the principles of innovation, partnership, and mutual prosperity, MENACC serves as a pivotal platform for businesses and entrepreneurs seeking to expand their reach and enhance their impact within these vibrant markets.
                        </div>
                    </div>
                </div>
                <div className="w-100 d-lg-none text-center mt-5">
                    <img className="fitImage" src="/images/logo-large.png" />
                    <div className="col">
                        <div className="h1 mt-3 mb-3 text-light">Our Story</div>
                        <div className="text-light storyText">
                            Welcome to the Middle Eastern and North African Chamber of Commerce (MENACC), a dynamic and forward-thinking organization dedicated to fostering business growth and economic collaboration of the Middle East and North Africa owned businesses in the United States of America. Founded on the principles of innovation, partnership, and mutual prosperity, MENACC serves as a pivotal platform for businesses and entrepreneurs seeking to expand their reach and enhance their impact within these vibrant markets.
                        </div>
                    </div>
                </div>
                <br/>
                <div className="h1 mt-3 mb-3 text-light text-center">The Team Behind</div>
                <div className="row w-100">
                    <div className="col text-center">
                        <img className="teamImage" src="/images/team/mike.jpeg" />
                        <div className="teamName text-light">Mike Aqrawi</div>
                        <div className="text-light position">Chairman</div>
                        <div className="m-2">
                            <a href="https://www.facebook.com/MikeAqrawi/" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-facebook mx-2" viewBox="0 0 16 16">
                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                                </svg>
                            </a>

                            <a href="https://www.linkedin.com/in/mikeaqrawi/" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-linkedin mx-2" viewBox="0 0 16 16">
                                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="col text-center">
                        <img className="teamImage" src="/images/team/faith.jpeg" />
                        <div className="teamName text-light">Faith Bautista</div>
                        <div className="text-light position">Secretary</div>
                    </div>
                    <div className="col text-center">
                        <img className="teamImage" src="/images/team/sinan.jpeg" />
                        <div className="teamName text-light">Sinan Aqrawi</div>
                        <div className="text-light position">Treasurer</div>
                        <div className="m-2">
                            <a href="https://www.linkedin.com/in/sinan-aqrawi/" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-linkedin mx-2" viewBox="0 0 16 16">
                                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row w-100">
                    <div className="col text-center">
                        <img className="teamImage" src="/images/team/karen.jpeg" />
                        <div className="teamName text-light">Karen Aboud</div>
                        <div className="text-light position">Director</div>
                        <div className="m-2">
                            <a href="https://www.linkedin.com/in/karen-a-a884b5181/" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-linkedin mx-2" viewBox="0 0 16 16">
                                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="col text-center">
                        <img className="teamImage" src="/images/team/anmar.jpeg" />
                        <div className="teamName text-light">Anmar Alkanaani</div>
                        <div className="text-light position">Director</div>
                    </div>
                </div>
            </div>
        </div>
       </>
    );
};

export default AboutPage;
