// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verticalCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.contact-page {
    min-height: 74vh;
    padding: 30px
}

.contact-form {
    margin: 50px auto;
}

.contact-form input {
    border-radius: 0;
    border: 1px solid #ccc;
}

.contact-form textarea {
    border-radius: 0;
    border: 1px solid #ccc;
}

.btn-send {
    background-color: #f5a623;
    color: white;
    border: none;
    border-radius: 0;
    padding: 10px 20px;
}

.btn-send:hover {
    background-color: #e69615;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/contact/contact.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB;AACJ;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".verticalCenter {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n}\n\n.contact-page {\n    min-height: 74vh;\n    padding: 30px\n}\n\n.contact-form {\n    margin: 50px auto;\n}\n\n.contact-form input {\n    border-radius: 0;\n    border: 1px solid #ccc;\n}\n\n.contact-form textarea {\n    border-radius: 0;\n    border: 1px solid #ccc;\n}\n\n.btn-send {\n    background-color: #f5a623;\n    color: white;\n    border: none;\n    border-radius: 0;\n    padding: 10px 20px;\n}\n\n.btn-send:hover {\n    background-color: #e69615;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
