import React, { useState } from 'react';
import './navbar.css';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="navbar">
            <a className="logo-container" href="/">
                <img src="/images/logo.png" alt="logo" className="logo" />
                <span className="logo-text">
                    MENA Directory USA
                </span>
            </a>
            <div className="menu-icon" onClick={toggleMenu}>
                {isMenuOpen ? '✖' : '☰'} 
            </div>
            <ul className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
                <li><a href="/">Home</a></li>
                <li><a href="/advertise">List Your Business</a></li>
                <li><a href="/contact">Contact Us</a></li>
                <li><a href="/about">About Us</a></li>
            </ul>
        </nav>
    );
};

export default Navbar;
