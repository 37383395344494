import React from 'react';
import './contact.css';

const ContactPage = () => {

    const handleSubmit = (e) => {
        e.preventDefault();
        let termsCheck = document.getElementById('termsCheck');
        if (!termsCheck.checked) {
            alert('Please agree to the terms and conditions.');
            return;
        }
        fetch('https://menadirectoryusa.com/api/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                full_name: e.target.full_name.value,
                company_name: e.target.company_name.value,
                email_address: e.target.email_address.value,
                phone_number: e.target.phone_number.value,
                message: e.target.message.value,
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            if (data['status'] === 'success') {
                alert('We have received your message. We will get back to you soon.');
            } else {
                alert('Something went wrong. Make sure you have filled all the fields.');
            }
        }
        )
    }

    return (
        <div className="contact-page text-center mt-5 verticalCenter">
            <div>
            <div className="h5">Looking for a great way to advertise your company?</div>
            <div className="h6">Contact us today and a representative will let you know what options are available to advertise your company!</div>
            <br/>
            <div className="h6">Send us a message using the form below or call today - <span className="strong">(619) 626-7453</span></div>
        
            <form className="contact-form" onSubmit={handleSubmit}>

                <input type="text" name="full_name" className="mt-3 form-control form-control-contact" placeholder="Full Name" />
                <input type="text" name="company_name" className="mt-3 form-control form-control-contact" placeholder="Company Name" />
                <input type="email" name="email_address" className="mt-3 form-control form-control-contact" placeholder="Email Address" />
                <input type="text" name="phone_number" className="mt-3 form-control form-control-contact" placeholder="Phone Number" />
                <textarea name="message" className="mt-3 form-control form-control-contact" placeholder="Message" rows="5"></textarea>
                {/* Checkbox / Agree to terms */}
                <div className="form-check mt-3 text-start">
                    <input type="checkbox" className="form-check-input" id="termsCheck" />
                    <label className="form-check-label" htmlFor="termsCheck">I agree to the Privacy Policy and Terms & Conditions.</label>
                </div>
                <button 
                    className="btn btn-danger mt-3"
                    style={{ height: '6vh', backgroundColor: '#e84c3c' }}
                >Send Message</button>
            </form>
            </div>
        </div>
            
    );
};

export default ContactPage;
