import React from 'react';
import './terms.css';

const Terms= () => {
    

    return (
       <>
       <div className="terms-container" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.jpg)` }}>
       <div className='our_terms'>
       <p className='head'>Our Terms</p>

        <div className="text-start text-light">
        <div className="h3">MENA CC INC Privacy Policy and Terms & Conditions</div>
        <div className="strong">Effective Date: January 1, 2024</div>
        <p className="text-light">This privacy policy describes how MENA CC INC ("MENA CC INC," "we," "us," or "our") processes information collected on our website, mobile app, and through your interactions with us (collectively, our "Services").</p>
        <p className="text-light strong">In some cases, we may provide you with additional or supplemental privacy notices to the extent they apply to you because of the products and services you obtain from us or different laws that may apply to you.</p>
        <p className="text-light strong">Some states may provide additional privacy rights. Please refer to the Additional Information Regarding Your State Privacy Rights section to learn more.</p>
        <p className="text-light">**By using any of our Services, you acknowledge the privacy practices we describe in this Privacy Policy. You also acknowledge that you have read this policy and other policies described. **</p>
        <p className="text-light">**By submitting your information, you hereby consent to receiving email, text messages, and phone calls from our company in regards to your use of our services and various programs that we have. **</p>
        <p className="text-light strong">SMS Consent will not be shared with any third party and affiliates for marketing, nor will the phone numbers for SMS purposes.</p>

        <div className="h4">Who We Are</div>
        <p className="text-light">MENA CC INC is committed to protecting your privacy. This privacy policy explains what information we collect, how we use it, and what choices you have about your information.</p>
        <div className="h4">Information We Collect</div>
        <p className="text-light">We collect information you provide to us directly and automatically through your use of our Services.</p>
        <div className="h4">Information You Provide</div>
        <p className="text-light">We collect information you provide to us, such as when you:</p>
        <ul>
            <li>Create an account with us</li>
            <li>Fill out a form or submit content through our Services</li>
            <li>Communicate with us via email, phone, or chat</li>
            <li>Participate in a contest, promotion, or survey</li>
            <li>Request customer support or information about our Services</li>
        </ul>
        <p className="text-light">The specific information we collect depends on the context in which you provide it, and could include:</p>
        <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Postal address</li>
            <li>Phone number</li>
            <li>Social security number</li>
            <li>Location and approximate value of your property</li>
            <li>Vehicle information, such as make, model, VIN, and vehicle features</li>
            <li>Age and/or date of birth</li>
            <li>Demographic information, such as race, ethnicity, and gender gathered pursuant to federal requirements</li>
            <li>Related loan products or services you have used in the past</li>
            <li>Financial information, such as income, assets, and net worth</li>
            <li>Home ownership status and employment status</li>
            <li>Insurance information</li>
            <li>Other profile data, including your interests and preferences</li>
            <li>Any other information you choose to provide</li>
        </ul>

        <div className="h4">Information We Automatically Collect</div>
        <p className="text-light">We automatically collect certain information about your interactions with us or our Services, including:</p>
        <ul>
            <li><span class="strong">Transactional Information:</span> When you make a purchase or complete a transaction, we collect information about the transaction, such as product or service details, financial details, and the date and location of the transaction.</li>
            <li><span class="strong">Device and Usage Information:</span>  We collect information about how you access our Services, including data about the device and network you use, such as hardware model, operating system version, mobile network, IP address, unique device identifiers, and device regional and language settings, browser type, and app version. We also collect information about your activity on our Services, such as access times, pages viewed, links clicked, products and services considered, and the page you visited before navigating to our Services.</li>
            <li><span class="strong">Your Content:</span>  As you allow through your device or application, we may collect information and content stored on your device, such as photos.</li>
            <li><span class="strong">Location Information:</span>  In accordance with your device permissions, we may collect information about the precise location of your device. You may stop the collection of precise location information at any time (see the "Your Choices" section below for details).</li>
            <li><span class="strong">Information Collected by Cookies and Similar Tracking Technologies:</span>  We (and those who perform work for us) use tracking technologies, such as cookies and web beacons, to collect information about your interactions with the Services. Cookies are small data files stored on your hard drive or in device memory that help us improve our Services and your experience, see which areas and features of our Services are popular and which emails and advertisements you view, authenticate users, reconstruct, or observe activity relating to a session or by a user for troubleshooting, issue resolution, and other purposes, and count visits to our websites.</li>
            <li><span class="strong">Web beacons</span> (also known as "pixel tags" or "clear GIFs") are electronic images that we use on our Services and in our emails to help deliver cookies, count visits, and understand usage and campaign effectiveness. In our mobile apps, we may also use technologies that are not browser-based like cookies. For example, our apps may include software development kits (known as SDKs) which are code that sends information about your use to a server. These SDKs allow us to track our conversions, bring you advertising both on and off the Service, and provide you with additional functionality. For more information about these tracking technologies and how to disable them, see the "Advertising and Analytics" section below.</li>
        </ul>

        <div className="h4">Information We Collect From Other Sources</div>
        <p className="text-light">We may obtain information about you from third parties. For example, we may collect information from:</p>
        <ul>
            <li>Identity or other verification services</li>
            <li>Credit bureaus (including your credit report)</li>
            <li>Advertising networks</li>
            <li>Mailing list providers</li>
            <li>Service providers</li>
            <li>Data brokers</li>
        </ul>
        <p className="text-light">We may also allow or require you to integrate or connect your MENA CC INC account with third-party services through service providers (such as Plaid).</p>
        <div className="h4">Information We Derive</div>
        <p className="text-light">We may derive information or draw inferences about you based on the information we collect. For example, we may make inferences about your location based on your IP address or city or postal code, or we may infer that you are looking to purchase certain products based on information you provide to us and your browsing behavior and past purchases.</p>
        <div className="h4">How We Use Your Information</div>
        <p className="text-light">We use the information we collect to:</p>
        <ul>
            <li>Provide our Services to you</li>
            <li>Fulfill or deliver the services you request</li>
            <li>Provide, maintain, improve, and develop new products, services, and features, including debugging and repairing errors in our Services</li>
            <li>Personalize your experience with us</li>
            <li>Send you technical notices, security alerts, support messages, and other transactional or relationship messages, including responding to your inquiries and comments</li>
            <li>Communicate with you about products, services, and events offered by MENA CC INC and others, and provide news and information that we think will interest you</li>
            <li>Monitor and analyze trends, usage, and activities in connection with our products and services</li>
            <li>Personalize the advertisements you see on third-party platforms and websites. For more information, see the "Advertising and Analytics" section below.</li>
            <li>Show you offers for third-party services personalized to you, based on information we have collected about you</li>
            <li>Facilitate contests, sweepstakes, and promotions, and process and deliver entries and rewards</li>
            <li>Detect, investigate, and help prevent security incidents and other malicious, deceptive, fraudulent, or illegal activity, and help protect the rights and property of MENA CC INC and others</li>
            <li>Comply with our legal and financial obligations</li>
            <li>Create de-identified, anonymized, or aggregated information</li>
            <li>Fulfill any other purpose at your direction</li>
        </ul>
        <div className="h4">Advertising and Analytics</div>
        <p className="text-light">We share personal information to allow others to provide analytics services and serve advertisements across the web and in mobile apps. These entities may use cookies, web beacons, device identifiers, and other technologies to collect information about your use of our Services and other websites and applications, including your IP address, web browser, mobile network information, pages viewed, time spent on pages or in mobile apps, links clicked, and conversion information.</p>
        <p className="text-light">This information may be used by MENA CC INC and others to, among other things, analyze and track data, reconstruct or observe activity relating to a session, determine the popularity of certain content, deliver advertising and content targeted to your interests on our Services and other websites, and better understand your online activity.</p>
        <p className="text-light">For more information about interest-based ads, or to opt out of having your web browsing information used for behavioral advertising purposes, please visit www.aboutads.info/choices (http://www.aboutads.info/choices). Your device may also include a feature ("Limit Ad Tracking" on iOS or "Opt Out of Interest-Based Ads" or "Opt Out of Ads Personalization" on Android) that allows you to opt out of having certain information collected through mobile apps used for behavioral advertising purposes. Certain state privacy laws also provide their residents with rights relating to submitting a direct opt-out for the sharing of personal information for purposes of cross-contextual, or interest-based, advertising. For more information, please see the "Additional Information Regarding Your State Privacy Rights" section below.</p>
        <div className="h4">Children's Privacy</div>
        <p className="text-light">MENA CC INC Services are not intended for children under 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you become aware that your child has provided us with personal information without your consent, please contact us.</p>
        <div className="h4">California Shine The Light</div>
        <p className="text-light">California law permits residents of California to request certain details about how their information is shared with third parties for direct marketing purposes. If you’re a California resident and would like to make such a request, please contact us.</p>
        <div className="h4">Processing of Information in the United States and Other Countries</div>
        <p className="text-light">MENA CC INC is headquartered in the United States. We offer goods and services to consumers in the United States. We have operations and vendors in the United States and other countries. Therefore, we and those that perform work for us may transfer your personal information to, or store or access it in, jurisdictions that may not provide levels of data protection that are equivalent to those of your home jurisdiction.</p>
        <div className="h4">Your Choices</div>

        <ul>
            <li><span className="strong">Right to Correct Account Information:</span> You may update and correct certain account information, or delete your account, by logging into your account or contacting us. Please note that we may retain certain information as required by law or for our legitimate business purposes. For more information, please see “Additional Information Regarding Your State Privacy Rights.”</li>
            <li><span className="strong">Location Information:</span> When you first launch any of our mobile apps that collect precise location information, or when you visit portions of our Services that collect precise location information, you’ll be asked to consent to the collection of this information. If you initially consent to our collection of such location information, you can subsequently stop the collection of this information at any time by changing the preferences on your mobile device. It is important to note that some Services may not function without precise location information. You may also stop a mobile app’s collection of location information by following the standard uninstall process to remove the app from your device.</li>
            <li><span className="strong">Do Not Track Signals:</span> You may be using an Internet browser that can communicate your privacy preferences to the website, including requests not to track your usage and browsing history. Our websites are configured to recognize those browser signals. If we recognize one of these signals – which may be referred to or are labeled by your browser as “do not track” or “opt-out preference” or “global preference” signals – we give you the option of submitting a request to opt-out of sale/sharing. For more information on opting out of the sale/sharing of personal information, please see “Additional Information Regarding Your State Privacy Rights,” below.</li>
            <li><span className="strong">Right to Opt-Out of Selling:</span> You have the right to opt out of the “sales” described above at any time by visiting our Do Not Sell My Personal Information webpage. We do not knowingly sell or share personal information about consumers under the age of 16.</li>
            <li><span className="strong">Right to Opt-Out of Personal Information Being Shared or Used In Targeted Advertising:</span> California requires that we notify you if we “Share” your personal information with third parties. In California, “Sharing” means we make available or otherwise disclose your information to third parties for cross-context behavioral advertising. Further, “cross-context behavioral advertising” means targeting of advertising to you based on your personal information obtained from your activity across businesses, websites, apps, and services other than our Services. Similarly, privacy laws in other states require we notify you if we use your information for Targeted Advertising. Targeted Advertising means displaying advertisements to you where the advertisement is selected based on personal information obtained from your activities over time and across nonaffiliated websites or online applications to predict your preferences or interests. Accordingly, we “Share” your personal information with third parties for purposes of cross-contextual advertising, and we use your information for Targeted Advertising. Specifically, we share your identifiers and internet or other network activity with our advertising partners. You have the right to opt out of “sharing” or Targeted Advertising by accessing our Do Not Sell My Personal Information webpage. We also honor browser “Do Not Track” signals, as noted above.</li>
            <li><span className="strong">Right to Limit Processing of Sensitive Personal Information:</span> In California, you have the right to limit our processing of your Sensitive Personal Information to only uses which are necessary to perform the services or provide the goods reasonably expected by an average consumer who requests such goods or services. When we collect your Sensitive Personal Information, we collect and use that information to perform the services or provide the goods for which that information is necessary and as reasonably expected by you. Under other state privacy laws, we are only permitted to collect certain pieces of Sensitive Personal Information after we have obtained your consent to do so. Where required, we get your agreement to collect and use Sensitive Personal Information.</li>
        </ul>
        <div className="h4">Retention of Personal Information</div>
        <p className="text-light">We will not retain your personal information for longer than is reasonably necessary to carry out the purposes we disclose in this policy.</p>
        <div className="h4">State Consumer Data Rights</div>
        <p className="text-light">Subject to certain limitations, you have the right to (1) request to know more about the categories and specific pieces of personal information we collect, use, disclose and sell, and to access your information, (2) request deletion of your personal information, (3) request correction of your personal information, and (4) not be discriminated against for exercising these rights.</p>
        <p className="text-light">You may make these requests by contacting us.</p>
        <div className="h4">California Financial Incentive Disclosure</div>
        <p className="text-light">California’s privacy law requires that we notify you if we provide you a program, benefit, or other offering, related to the collection, deletion, or sale of personal information, which it defines as a “financial incentive.” We may offer you certain financial incentives that may result in different prices, rates, or quality levels (for example, contests and referral programs). Through these offerings, consumers provide us with some personal information (e.g., names, emails, phone numbers) when they opt-in to our programs. There is no obligation to opt-in and consumers may opt-out at any time. The details of each program are contained in the program offering. We offer these programs, among other things, to enhance our relationship with you so you can enjoy more of our products/services at a lower price. We invest heavily in our marketing and brands, in part, so we can provide programs to our customers. Consumer data is valuable to our business only when it is combined with a sufficient number of other consumer data and after it is enhanced by our efforts herein described. The value to our business of any individual consumer’s personal information is dependent on a number of factors, including, for example, whether and to what extent you take advantage of any offerings, whether and to what extent you opt out of any offerings, and whether we are able to enhance the data through our efforts herein described. Our ability to create any value from the programs is heavily based on our ability to leverage said intellectual properties. We do not calculate the value of consumer data in our accounting statements.</p>
        <div className="h4">California Consumer Data Requests</div>
        <p className="text-light">In 2023, we received 421 consumer data requests from California consumers. This includes requests from people to know, access, or delete their data. We also received 48,406 requests from people who opted out of data sharing.</p>
        <p className="text-light">Here’s a breakdown of those consumer requests:</p>
        <ul>
        <li>119 Right To Know/Access requests</li>
        <li>302 Right To Delete requests</li>
        </ul>
        <p className="text-light">It’s taken us a median of 2 days to process and respond to each request.</p>
        <div className="h4">Federal Privacy Notice</div>
        <p className="text-light">Some companies within MENA CC INC are required to provide an additional Privacy Notice under the Gramm-Leach-Bliley Act. For convenience, these notices are provided below.</p>
        <div className="h4">Contact Information</div>
        <p className="text-light">If you have any questions about this Privacy Policy or MENA CC INC’s information practices, please contact us at <a href="tel:6196267453">619.626.7453</a> or <a href="mailto:info@menacoc.com">Info@menacoc.com</a>.</p>
        <p className="text-light">This privacy policy is effective as of the Effective Date shown above. We may change this policy from time to time, so please check back periodically for updates.</p>
        </div>
       </div>
      </div>
       </>
    );
};

export default Terms;
