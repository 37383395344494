// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-container {
    padding: 40px;
    background-color: #01021e;
    min-height: 85vh;
}

.head {
    color: white; 
    text-align: center; 
    font-size: 32px;
    margin: 20px 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); 
    font-weight: 500;
  }

.text{
    color: white; 
    font-size: 24px; 
    margin-bottom: 15px; 
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); 
    font-weight: 300;


}

p {
    margin: 15px 0 !important
}`, "",{"version":3,"sources":["webpack://./src/components/terms/terms.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,2CAA2C;IAC3C,gBAAgB;EAClB;;AAEF;IACI,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,2CAA2C;IAC3C,gBAAgB;;;AAGpB;;AAEA;IACI;AACJ","sourcesContent":[".terms-container {\r\n    padding: 40px;\r\n    background-color: #01021e;\r\n    min-height: 85vh;\r\n}\r\n\r\n.head {\r\n    color: white; \r\n    text-align: center; \r\n    font-size: 32px;\r\n    margin: 20px 0;\r\n    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); \r\n    font-weight: 500;\r\n  }\r\n\r\n.text{\r\n    color: white; \r\n    font-size: 24px; \r\n    margin-bottom: 15px; \r\n    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); \r\n    font-weight: 300;\r\n\r\n\r\n}\r\n\r\np {\r\n    margin: 15px 0 !important\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
