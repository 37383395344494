import React, { useState, useEffect } from 'react';
import './listing.css';
import { FaPhoneAlt, FaMapMarkerAlt, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import MapPopup from '../mappopup/mappopup';

const Listing = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [places, setPlaces] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [showMap, setShowMap] = useState(false);
    const [mapCoordinates, setMapCoordinates] = useState({ latitude: null, longitude: null });
    const location = useLocation();
    const navigate = useNavigate();

    const states = [
        "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
        "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho",
        "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana",
        "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi",
        "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey",
        "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma",
        "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
        "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington",
        "West Virginia", "Wisconsin", "Wyoming"
    ];

    const getQueryParams = () => {
        const searchParams = new URLSearchParams(location.search);
        const keywordParam = searchParams.get('keyword') || '';
        const stateParam = searchParams.get('state') || '';
        const pageParam = parseInt(searchParams.get('page')) || 1;

        return { keywordParam, stateParam, pageParam };
    };

    const fetchData = async (keyword, state, page) => {
        let apiUrl = `https://menadirectoryusa.com/api/search?keyword=${keyword}&page=${page}`;
        if (state) {
            apiUrl += `&state=${state}`;
        }

        try {
            const response = await fetch(apiUrl);
            const data = await response.json();

            if (data.status === 'success') {
                setPlaces(data.data);
                const totalResults = data.total;
                const calculatedTotalPages = Math.ceil(totalResults / 10);

                setTotalPages(calculatedTotalPages);

                if (page > calculatedTotalPages) {
                    setCurrentPage(calculatedTotalPages);
                } else {
                    setCurrentPage(page);
                }
            } else {
                setPlaces([]);
                setTotalPages(1);
                setCurrentPage(1);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setPlaces([]);
            setTotalPages(1);
            setCurrentPage(1);
        }
    };

    useEffect(() => {
        const { keywordParam, stateParam, pageParam } = getQueryParams();
        setKeyword(keywordParam);
        setSelectedState(stateParam);
        fetchData(keywordParam, stateParam, pageParam);
    }, [location.search]);

    const handleSearch = () => {
        const newParams = new URLSearchParams();
        if (keyword) newParams.append('keyword', keyword);
        if (selectedState) newParams.append('state', selectedState);
        newParams.append('page', 1);

        navigate(`/lists?${newParams.toString()}`);
    };

    const handlePageChange = (newPage) => {
        if (newPage <= totalPages && newPage >= 1) {
            setCurrentPage(newPage);
            const newParams = new URLSearchParams(location.search);
            newParams.set('page', newPage);
            navigate(`/lists?${newParams.toString()}`);
        }
    };

    const handleShowMap = (latitude, longitude) => {
        if (latitude === null || longitude === null) {
            alert('No map available for this location.');
        } else {
            setMapCoordinates({ latitude, longitude });
            setShowMap(true);
        }
    };

    const handleCallNow = (phone) => {
        if (!phone) {
            alert('No phone number available for this location.');
        } else {
            window.location.href = `tel:${phone}`;
        }
    };

    const handleCloseMap = () => {
        setShowMap(false);
    };

    return (
        <>
            <div className='lists-page' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.jpg)` }}>
                <div className="search-container" style={{ height: 'auto' }}>
                    <div className="search-bar-frame mt-3">
                        <input
                            type="text"
                            className="form-control form-control-search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            placeholder="Search..."
                        />
                        <select 
                            className="form-select form-select-search" 
                            value={selectedState} 
                            onChange={(e) => {
                                const newState = e.target.value === selectedState ? '' : e.target.value;
                                setSelectedState(newState);
                            }}
                        >
                            <option value="">Select State</option>
                            {states.map((state) => (
                                <option key={state} value={state}>{state}</option>
                            ))}
                        </select>
                        <button 
                            className="btn btn-danger" 
                            onClick={handleSearch}
                            style={{ height: '6vh', backgroundColor: '#e84c3c' }}
                        >
                            Search
                        </button>
                    </div>
                </div>

                <div className="listing-container">
                    <h3 style={{ color: 'white', textAlign: 'center' }}>Search Results</h3>
                    {places.length > 0 ? (
    places.map((place, index) => {
        const imageSrc = place.image_1 || place.additional_image || `${process.env.PUBLIC_URL}/images/default.jpg`;
        return (
            <div key={index} className={`listing-item ${place.featured ? "featured" : ""}`}>
                <a href={`/details/${place.entry_id}`} className="hideDecoration text-dark">
                    <img 
                        src={imageSrc} 
                        alt={place.name} 
                        className="listing-image" 
                        onError={(e) => { e.target.onerror = null; e.target.src = `${process.env.PUBLIC_URL}/images/default.jpg` }} // Fallback for broken images
                    />
                </a>
                <div className="listing-details">
                    <h2><a href={`/details/${place.entry_id}`} className="hideDecoration text-dark">{place.title}</a></h2>
                    <p><a href={`/details/${place.entry_id}`} className="hideDecoration text-dark">{place.address}</a></p>
                    <div style={{ marginTop: '5px' }}>
                        <button
                            onClick={() => handleCallNow(place.phone)}
                            style={{ color: 'red', marginRight: '30px', background: 'none', border: 'none', cursor: 'pointer' }}
                        >
                            <FaPhoneAlt className="icon" /> <u>Call Now</u>
                        </button>
                        <button
                            onClick={() => handleShowMap(place.latitude, place.longitude)}
                            style={{ color: 'red', background: 'none', border: 'none', cursor: 'pointer' }}
                        >
                            <FaMapMarkerAlt className="icon" /><u>Show Map</u>
                        </button>
                    </div>
                </div>
                {place.featured ?
                    ( <img src={`${process.env.PUBLIC_URL}/images/featured.png`} alt="Featured" className="featured-badge" /> ) : null
                }
            </div>
        );
    })
) : (
    <p style={{ color: 'white' }}>No results found.</p>
)}


                    <div className="pagination" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <button 
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            style={{
                                backgroundColor: '#e84c3c',
                                color: 'white',
                                border: 'none',
                                padding: '10px 20px',
                                marginRight: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                borderRadius: '5px',
                            }}
                        >
                            <FaChevronLeft /> Prev
                        </button>
                        <span style={{ alignSelf: 'center', color: 'white' }}>{`Page ${currentPage} of ${totalPages}`}</span>
                        <button 
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            style={{
                                backgroundColor: '#e84c3c',
                                color: 'white',
                                border: 'none',
                                padding: '10px 20px',
                                marginLeft: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                borderRadius: '5px',
                            }}
                        >
                            Next <FaChevronRight />
                        </button>
                    </div>
                </div>

                {showMap && (
                    <MapPopup
                        latitude={mapCoordinates.latitude}
                        longitude={mapCoordinates.longitude}
                        onClose={handleCloseMap}
                    />
                )}
            </div>
        </>
    );
};

export default Listing;
