import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './details.css';
import { FaLocationArrow, FaPhoneAlt, FaEnvelope, FaFirefoxBrowser, FaFacebook, FaTwitterSquare, FaLinkedin, FaYoutube, FaInstagramSquare, FaStar, FaAngleLeft, FaAngleRight, FaCheckSquare } from 'react-icons/fa';

const DetailsPage = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [banner, setBanner] = useState(null);
    const [images, setImages] = useState([]);
    const [currentImage, setCurrentImage] = useState(0);
    const [features, setFeatures] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://menadirectoryusa.com/api/record/${id}`); // Replace with your API URL
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const result = await response.json(); // Parse the JSON from the response
                setData(result['data']); // Set the data in state
                let all_images = result['data']['additional_image'].split(',')
                setBanner(all_images[0])
                setImages(all_images.slice(1));
                setFeatures(result['data']['features'].split(','));
            } catch (error) {
                console.error(error); // Log any errors to the console
            }
        };

        fetchData(); // Call the function when the component mounts
    }, [id]); // Empty dependency array to ensure it runs only once

    return (
        <div className="details-page">
            <div className="details-head">
                {
                    data && banner ? (
                        <img alt="business" className="details-background-image" src={banner} />
                    ) : (
                        <img alt="business" className="details-background-image" src={`${process.env.PUBLIC_URL}/images/default.jpg`} />
                    )
                }
            </div>

            <div className="details-container">
                <div className="row w-100 firstbaseline m-auto end">
                    <div className="col">
                        {/* Desktop */}
                        <div className="details-title d-none d-lg-flex">
                            <div className="details-logo-container">
                                { data && data.image_1 ? (
                                        <img alt="business" className="details-logo" src={data.image_1} />
                                    ) : (
                                        <img alt="business" className="details-logo" src={`${process.env.PUBLIC_URL}/images/default.jpg`} />
                                    )
                                }
                            </div>
                            <div className="details-name">
                                <h1>
                                    <div className="rating">
                                        <FaStar className="rating-star" />
                                        <FaStar className="rating-star" />
                                        <FaStar className="rating-star" />
                                        <FaStar className="rating-star" />
                                        <FaStar className="rating-star" />
                                    </div>
                                    { data && data.title ? ( data.title ) : ( '' ) }
                                </h1>
                                <p>
                                    { data && data.sub_title ? ( data.sub_title ) : ( '' )  }
                                </p>
                            </div>
                        </div>
                        {/* Mobile */}
                        <div className="d-lg-none">
                            <div className="details-logo-container m-auto">
                                { data && data.image_1 ? (
                                        <img alt="business" className="details-logo" src={data.image_1} />
                                    ) : (
                                        <img alt="business" className="details-logo" src={`${process.env.PUBLIC_URL}/images/default.jpg`} />
                                    )
                                }
                            </div>
                            <div className="rating rating-mobile m-3">
                                <FaStar className="rating-star" />
                                <FaStar className="rating-star" />
                                <FaStar className="rating-star" />
                                <FaStar className="rating-star" />
                                <FaStar className="rating-star" />
                            </div>
                            <div className="details-name">
                                <h1>
                                    { data && data.title ? ( data.title ) : ( '' ) }
                                </h1>
                                <p>
                                    { data && data.sub_title ? ( data.sub_title ) : ( '' )  }
                                </p>
                            </div>
                        </div>
                        <hr/>
                        {/* Desktop */}
                        {images.length ? ( <>
                            <div className="images-slider d-none d-lg-flex">
                                <FaAngleLeft className="slider-arrow" onClick={() => currentImage ? setCurrentImage(currentImage-1) : setCurrentImage(0)} />
                                {/* <img alt="business" className="details-image" src={`${process.env.PUBLIC_URL}/images/default.jpg`} /> */}
                                {/* <img alt="business" className="details-image" src={`${process.env.PUBLIC_URL}/images/default.jpg`} /> */}
                                {images[currentImage] ? (
                                    <img alt="business" className="details-image" src={images[currentImage]} />
                                ) : (<></>)}
                                {images[currentImage+1] ? (
                                    <img alt="business" className="details-image" src={images[currentImage+1]} />
                                ) : (<></>)}
                                <FaAngleRight className="slider-arrow" onClick={() => currentImage !== images.length - 2 ? setCurrentImage(currentImage+1) : setCurrentImage(images.length - 2)} />
                            </div>
                            {/* Mobile */}
                            <div className="images-slider d-lg-none">
                                <FaAngleLeft className="slider-arrow" onClick={() => currentImage ? setCurrentImage(currentImage-1) : setCurrentImage(0)} />
                                {images[currentImage] ? (
                                    <img alt="business" className="details-image" src={images[currentImage]} />
                                ) : (<><img alt="business" src="https://placehold.co/200x200" /></>)}
                                <FaAngleRight className="slider-arrow" onClick={() => currentImage !== images.length - 2 ? setCurrentImage(currentImage+1) : setCurrentImage(images.length - 2)} />
                            <hr/>
                            </div>
                        </> ) : (<></>)}
                        <div className="h5 mb-3">About Us</div>
                        <div className="details-about">
                            <p style={{ whiteSpace: 'pre-line' }}>
                                { data && data.description ? ( data.description ) : ( 'This company does not have a description yet.' ) }
                            </p>
                        </div>
                        <hr/>
                        <div className="h5 mb-3">Features</div>

                        {features.length ? features.map((feature, index) => (
                            <div className="feature" key={index}><FaCheckSquare className="icon feature-icon" /> {feature}</div>
                        )) : (<p>No features available</p>)}
                        
                        <hr />
                    </div>
                    <div className="col-auto">
                        <div className="details-side-box">
                            <div className="gmap_canvas">
                                { data && data.latitude && data.longitude ? (
                                    // <iframe className="gmap_iframe" src={`https://maps.google.com/maps?q=${data.latitude},${data.longitude}&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed`} />
                                    // <iframe className="gmap_iframe" src={`https://maps.google.com/maps?q=${data.latitude},${data.longitude}&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed`} />
                                    // <iframe className="gmap_iframe" src="https://maps.google.com/maps?q=32.78802215,-116.95177080&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=&amp;output=embed" />
                                    <iframe
                                        className="gmap_iframe"
                                        src={`https://maps.google.com/maps?q=${data.latitude},${data.longitude}&t=&z=14&ie=UTF8&iwloc=&output=embed`}
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                        title="Google Maps"
                                        />
                                    ) : (
                                        <iframe
                                        className="gmap_iframe"
                                        src={`https://maps.google.com/maps?q=&t=&z=14&ie=UTF8&iwloc=&output=embed`}
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                        title="Google Maps"
                                    />
                                )                                    
                                }
                            </div>
                            <div className="p-4">
                                <div className="location">
                                    <FaLocationArrow className="icon" /> 
                                    {
                                        data && data.longitude && data.latitude ? (
                                            <a className="hideDecoration" href={`https://www.google.com/maps/search/?api=1&query=${data.latitude},${data.longitude}`} target="_blank" rel="noreferrer">
                                                { data && data.address ? ( data.address ) : ( 'Address not available' ) }
                                            </a>
                                        ) : (
                                            "Address not available"
                                        )
                                            
                                    }
                                </div>
                                <hr/>
                                <div className="h5 mb-3">Contact</div>
                                <div className="mb-1"><FaPhoneAlt className="icon" /> 
                                    {data && data.phone ? (
                                        <a href={`tel:${data.phone}`} className="hideDecoration">{data.phone}</a>
                                    ) : ("Phone number not available")}
                                </div>
                                <div className="mb-1"><FaEnvelope className="icon" /> 
                                    {data && data.email ? (
                                        <a href={`mailto:${data.email}`} className="hideDecoration">{data.email}</a>
                                    ) : ("Email not available")
                                    }
                                </div>
                                <div className="mb-1"><FaFirefoxBrowser className="icon" /> 
                                    {data && data.website ? (
                                        <a href={`${data.website}`} className="hideDecoration">{data.website}</a>
                                    ) : ("Website not available")
                                    }
                                </div>
                                <hr/>
                                <div className="h5 mb-3">Social Media</div>
                                <div className="mb-1"><FaFacebook className="icon" /> 
                                    {data && data.facebook ? (
                                        <a href={`${data.facebook}`} className="hideDecoration">{data.facebook}</a>
                                    ) : ("Facebook not available")
                                    }
                                </div>
                                <div className="mb-1"><FaTwitterSquare className="icon" /> 
                                    {data && data.twitter ? (
                                        <a href={`${data.twitter}`} className="hideDecoration">{data.twitter}</a>
                                    ) : ("Twitter not available")
                                    }
                                </div>
                                <div className="mb-1"><FaLinkedin className="icon" /> 
                                    {data && data.linkedin ? (
                                        <a href={`${data.linkedin}`} className="hideDecoration">{data.linkedin}</a>
                                    ) : ("Linkedin not available")
                                    }
                                </div>
                                <div className="mb-1"><FaYoutube className="icon" /> 
                                    {data && data.youtube ? (
                                        <a href={`${data.youtube}`} className="hideDecoration">{data.youtube}</a>
                                    ) : ("Youtube not available")
                                    }
                                </div>
                                <div className="mb-1"><FaInstagramSquare className="icon" /> 
                                    {data && data.instagram ? (
                                        <a href={`${data.instagram}`} className="hideDecoration">{data.instagram}</a>
                                    ) : ("Instagram not available")
                                    }
                                </div>
                                <hr/>
                                <div className="h5 mb-3">Opening Hours</div>
                                <table className="timetable table table-borderless">
                                    <thead>
                                        <tr>
                                            <th>Day</th>
                                            <th>Hours</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr> <td>Monday</td> <td>{data && data.time_monday ? (data.time_monday) : ('Data not available')}</td></tr>
                                        <tr> <td>Tuesday</td> <td>{data && data.time_tuesday ? (data.time_tuesday) : ('Data not available')}</td></tr>
                                        <tr> <td>Wednesday</td> <td>{data && data.time_wednesday ? (data.time_wednesday) : ('Data not available')}</td></tr>
                                        <tr> <td>Thursday</td> <td>{data && data.time_thursday ? (data.time_thursday) : ('Data not available')}</td></tr>
                                        <tr> <td>Friday</td> <td>{data && data.time_friday ? (data.time_friday) : ('Data not available')}</td></tr>
                                        <tr> <td>Saturday</td> <td>{data && data.time_saturday ? (data.time_saturday) : ('Data not available')}</td></tr>
                                        <tr> <td>Sunday</td> <td>{data && data.time_sunday ? (data.time_sunday) : ('Data not available')}</td></tr>
                                    </tbody>
                                </table>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailsPage;
