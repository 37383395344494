// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fitImage {
    width: 30vw;
}

.storyTitle {
    font-size: 46px;
    font-weight: bold;
    margin: 25px 0;
}

.storyText {
    font-size: 18px;
}

.teamName {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0;
}

.teamImage {
    width: 200px;
    margin: 10px 0;
    border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/about/about.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".fitImage {\n    width: 30vw;\n}\n\n.storyTitle {\n    font-size: 46px;\n    font-weight: bold;\n    margin: 25px 0;\n}\n\n.storyText {\n    font-size: 18px;\n}\n\n.teamName {\n    font-size: 24px;\n    font-weight: bold;\n    margin: 10px 0;\n}\n\n.teamImage {\n    width: 200px;\n    margin: 10px 0;\n    border-radius: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
