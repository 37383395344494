import React from 'react';
import './footer.css';
import { FaFacebookF, FaInstagram, FaTwitter, FaPhone, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-content">
                    <div className="footer-section">
                        <img className="footer-logo" src="/images/logo.png" alt="Logo" />
                    </div>
                    <div className="footer-section">
                        <h4 className="text-start">MENA Directory USA</h4>
                        <p className='p-class'>
                            <FaMapMarkerAlt /> 
                            <span className="footer-text">844 E Washington Ave, El Cajon, CA 92020</span><br />
                            <span className="footer-text"> </span>
                        </p>
                        <p className='p-class'>
                            <FaPhone /> 
                            <span className="footer-text"> <a href="tel:6196267453" className="removeDecoration">619.626.7453</a></span>
                        </p>
                        <p className='p-class'>
                            <FaEnvelope /> 
                            <span className="footer-text"> <a href="mailto:info@menacoc.com" className="removeDecoration">info@menacoc.com</a></span>
                        </p>
                    </div>
                    <div className="footer-section">
                        <h4>Site Navigation</h4>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><a href="/advertise">List Your Business</a></li>
                            <li><a href="/contact">Contact Us</a></li>
                            <li><a href="/terms">Our Terms</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="h6">Copyright MENA © {year}</div>
            </div>
        </footer>
    );
};


export default Footer;
