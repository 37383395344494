// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-page {
    padding: 50px 50px;

    /* Center content */
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
        padding: 30px 30px;
    }
}


.card-aa {
    width: 900px;
}

.form-label {
    font-size: 16px;
    color: #565656;
}

.card-img-top {
    object-fit: fill;
}

.radius {
    border-radius: 5px !important
}

.bg-gold {
    background: rgb(250,235,69);
    background: linear-gradient(27deg, rgba(250,235,69,1) 16%, rgba(240,200,77,0.95) 38%, rgba(250,235,69,1) 71%, rgba(240,200,77,1) 100%);
}`, "",{"version":3,"sources":["webpack://./src/components/advertise/advertise.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;;IAEvB;QACI,kBAAkB;IACtB;AACJ;;;AAGA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;AACJ;;AAEA;IACI,2BAA2B;IAC3B,sIAAsI;AAC1I","sourcesContent":[".p-page {\n    padding: 50px 50px;\n\n    /* Center content */\n    display: flex;\n    justify-content: center;\n\n    @media (max-width: 768px) {\n        padding: 30px 30px;\n    }\n}\n\n\n.card-aa {\n    width: 900px;\n}\n\n.form-label {\n    font-size: 16px;\n    color: #565656;\n}\n\n.card-img-top {\n    object-fit: fill;\n}\n\n.radius {\n    border-radius: 5px !important\n}\n\n.bg-gold {\n    background: rgb(250,235,69);\n    background: linear-gradient(27deg, rgba(250,235,69,1) 16%, rgba(240,200,77,0.95) 38%, rgba(250,235,69,1) 71%, rgba(240,200,77,1) 100%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
